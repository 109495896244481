
import Base from '@/mixins/Base.vue';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    data() {
        return {
            item: {
                title: '',
                description: '',
                icon: '',
                starts_at: moment()
                    .toDate(),
                ends_at: moment()
                    .add(1, 'month')
                    .toDate(),
            },
        };
    },
    methods: {
        createItem(): void {
            this.post(`projects/${this.$route.params.project_id}/steps`, {
                ...this.item,
            }).then(() => {
                this.$router.push({ name: 'projects.steps.list', ...this.$route.params });
                this.$emit('reload');
            });
        },

    },
});
